import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Check } from 'mdi-material-ui';
import { FunctionalPage } from '../../component';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT,
  RXSTATE_CERTIFICATION_MY_APPROVALS,
  RXSTATE_CERTIFICATION_MY_APPROVAL_PAGE,
  RXFORM_CERTIFICATION_MY_APPROVAL,
  MY_APPROVAL_TYPES,
} from '../../constant';

const assetChildActiveIcon = require('../../../../asset/asset-child-active.png');
const assetChildInactiveIcon = require('../../../../asset/asset-child-inactive.png');

const tabs = () => [
  {
    label: LocalizedString.certificationRequestPage.buttonCaptionAsset,
    activeIcon: assetChildActiveIcon,
    inactiveIcon: assetChildInactiveIcon,
  },
  {
    label: LocalizedString.certificationRequestPage.buttonCaptionApproval,
    iconName: Check,
  },
];
const defaultTab = tabs()[1].label;

const CertificationMyApprovalPage = ({
  onAppear, downloading, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  myApprovals,
}) => (
  <FunctionalPage
    data={RXSTATE_CERTIFICATION_MY_APPROVALS}
    uiPage={RXSTATE_CERTIFICATION_MY_APPROVAL_PAGE}
    tableColumns={[
      {
        title: LocalizedString.myApprovalPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.myApprovalPage.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.myApprovalPage.labelApprovalStatus, field: 'approvalStatus', sorting: !downloading },
      {
        title: LocalizedString.myApprovalPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.myApprovalPage.labelType,
        field: 'resultType',
        type: FILTER_TYPE_DROPDOWN,
        data: MY_APPROVAL_TYPES,
      },
      {
        title: LocalizedString.myApprovalPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
    ]}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onAppearWithDetailRoute={(id) => onAppear(id, defaultTab)}
    onViewPressed={(id, history) => onViewPressed(
      id, history, defaultTab, myApprovals,
    )}
    title={LocalizedString.myApprovalPage.menuTitle}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {null}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CERTIFICATION_MY_APPROVAL,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CertificationMyApprovalPage);

CertificationMyApprovalPage.propTypes = {
  myApprovals: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
};
