import { connect } from 'react-redux';
import { getData } from '../../../helper';
import {
  clearAssetDocuments, downloadAssetDocumentsAsync, setAlertErrorMessage,
  setAssetDocumentSelectedOrderBy, setAssetDocumentSelectedPageSize,
  setCertificationRequestDialogVisibility,
} from '../../../redux/action';
import SelectFileDialog from './select-file-dialog.presentation';
import { FILE_EXTENSION_PDF } from '../../../constant';

const mapStateToProps = (state) => ({
  assetDocuments: getData(state.assetDocuments, state.uiAssetDocument),
  dialogVisibility: state.uiCertificationRequest.dialogVisibility,
  downloading: state.uiAssetDocument.downloading,
  currentPage: state.assetDocuments?.meta?.currentPage || 0,
  selectedPageSize: state.uiAssetDocument?.selectedPageSize || 1,
  totalCount: state.assetDocuments?.meta?.totalCount || 0,
  dialogMode: state.uiCertificationRequest.dialogMode,
  orderBy: state.uiAssetDocument?.orderBy || '',
});

const mapDispatchToProps = (dispatch) => ({
  onSortPressed: (orderBy) => {
    dispatch(setAssetDocumentSelectedOrderBy(orderBy));
    dispatch(clearAssetDocuments());
    dispatch(downloadAssetDocumentsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setCertificationRequestDialogVisibility(false, ''));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetDocumentsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetDocumentSelectedPageSize(pageSize));
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: (selectedRows) => {
    const data = selectedRows.map((x) => ({ url: x.file, fileName: x.fileName }));
    const fetchFile = (file) => fetch(file.url).then((res) => res.blob());

    const exportFile = (file, fileName) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.setAttribute('download', fileName);
      a.click();
    };

    for (let i = 0; i < data.length; i += 1) {
      fetchFile(data[i]).then((file) => exportFile(file, data[i].fileName));
    }

    dispatch(setCertificationRequestDialogVisibility(false, ''));
  },
  onPdfPressed: async (fileUrl, fileName) => {
    try {
      const extension = fileUrl?.match(/(\.[^.]*)$/g)[0];
      const response = await fetch(fileUrl);
      if (extension === FILE_EXTENSION_PDF) {
        const arrbuff = await response.arrayBuffer();
        const blob = new Blob([arrbuff], {
          type: 'application/pdf',
        });
        const newWindow = window.open('', '_blank');
        const dataUrl = window.URL.createObjectURL(blob);

        const title = newWindow.document.createElement('title');
        const iframe = newWindow.document.createElement('iframe');

        newWindow.document.head.appendChild(title);

        iframe.setAttribute('src', dataUrl);
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('height', '100%');
        iframe.setAttribute('type', 'application/pdf');

        newWindow.document.body.appendChild(iframe);
      } else {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
      }
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFileDialog);
