import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  ASSET_NEED_CERTIFICATION_FILTER_STRING, CERT_REQUEST_DIALOG_MODE_ADD_ASSET,
  CERT_REQUEST_DIALOG_MODE_SELECT_FILE, INITIAL_ORDER_BY_ASSET_DOCUMENTS, INITIAL_ORDER_BY_ASSETS,
  INITIAL_ORDER_BY_CERTIFICATION_TYPES, MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST,
  PAGE_MODE_TABLE, RXFIELD_CERTIFICATION_REQUEST_CATEGORY, RXFIELD_CERTIFICATION_REQUEST_OWNER,
  RXFIELD_CERTIFICATION_REQUEST_TYPE, RXFORM_CERTIFICATION_REQUEST,
} from '../../../constant';
import {
  getData, transformDropdownData, transformInitialValues,
} from '../../../helper';
import {
  addCertificationRequestAsync, clearAssetDocuments, clearAssetLocations, clearAssets,
  clearAssetTypes, clearCertificationTypes, clearMyAssetLocations, downloadAssetDocumentsAsync,
  downloadAssetLocationsAsync, downloadAssetsAsync, downloadAssetTypesAsync,
  downloadCertificationTypesAsync, downloadMyAssetLocationsAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setAssetAdvancedFilterDialogSelectedFilterString,
  setAssetDocumentAdvancedFilterDialogSelectedFilterString, setAssetDocumentSearchText,
  setAssetDocumentSelectedOrderBy, setAssetDocumentSelectedPageSize,
  setAssetLocationSearchText, setAssetSearchText, setAssetSelectedOrderBy, setAssetSelectedPageSize,
  setAssetTypeSearchText, setCertificationRequestDialogVisibility,
  setCertificationRequestSelectedCategory, setCertificationRequestSelectedOwner,
  setCertificationRequestSelectedType, setCertificationRequestTappedId,
  setCertificationTypeAdvancedFilterDialogSelectedFilterString, setCertificationTypeSearchText,
  setCertificationTypeSelectedOrderBy, setCertificationTypeSelectedPageSize,
  setMyAssetLocationSearchText,
} from '../../../redux/action';
import CreateCertificationRequestPage from './create.presentation';

const getInitialValues = (state) => {
  const {
    certificationRequests, currentUser, uiFunctionalPage, uiCertificationRequest,
  } = state;
  const { data } = certificationRequests;
  const { downloadingDeleting, tappedId } = uiCertificationRequest;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found, {
    pic: currentUser?.fullName || '',
  }) : { pic: currentUser?.fullName || '' };
  return result;
};

const getOwnersData = (state) => {
  const { myAssetLocations, uiCertificationRequest: { myAssetLocationSearchBarText } } = state;
  if (myAssetLocations.length) {
    const data = myAssetLocationSearchBarText
      ? myAssetLocations.filter((x) => x.name.toLowerCase()
        .includes(myAssetLocationSearchBarText.toLowerCase()))
      : myAssetLocations;
    const result = transformDropdownData(data);
    return result;
  }
  return [];
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  assets: getData(state.assets, state.uiAsset),
  assetTypes: transformDropdownData(state.assetTypes.data),
  certificationTypes: transformDropdownData(state.certificationTypes.data),
  locations: transformDropdownData(state.assetLocations.data),
  owners: getOwnersData(state),
  addingEditing: state.uiCertificationRequest.addingEditing,
  dialogVisibility: state.uiCertificationRequest.dialogVisibility,
  downloadingAssets: state.uiAsset.downloading,
  loadingAssetTypes: state.uiAsset.downloadingAssetTypes,
  loadingCertificationTypes: state.uiCertificationType.downloading,
  loadingLocations: state.uiAsset.downloadingAssetLocations,
  loadingOwners: state.uiCertificationRequest.downloadingMyAssetLocations,
  assetCurrentPage: state.assets?.meta?.currentPage || 0,
  assetSelectedPageSize: state.uiAsset?.selectedPageSize || 1,
  assetTotalCount: state.assets?.meta?.totalCount || 0,
  assetFilterString: state.uiAsset?.filterString || '',
  assetOrderBy: state.uiAsset?.orderBy || '',
  assetSearchBarText: state.uiAsset?.searchBarText || '',
  dialogMode: state.uiCertificationRequest.dialogMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAddAssetPressed: () => {
    dispatch(setCertificationRequestDialogVisibility(true, CERT_REQUEST_DIALOG_MODE_ADD_ASSET));
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(
      ASSET_NEED_CERTIFICATION_FILTER_STRING,
    ));
    dispatch(setAssetSearchText(''));
    dispatch(clearAssets());
    dispatch(setAssetSelectedPageSize(20));
    dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddPressed: () => {
    dispatch(setCertificationRequestDialogVisibility(false, ''));
  },
  onAppear: () => {
    dispatch(reset(RXFORM_CERTIFICATION_REQUEST));
    dispatch(setCertificationRequestSelectedCategory(''));
    dispatch(setCertificationRequestSelectedOwner(''));
    dispatch(setCertificationRequestSelectedType(''));
    dispatch(setActiveSideMenuItem(MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST));
    dispatch(setCertificationRequestTappedId(''));
    dispatch(setMyAssetLocationSearchText(''));
    dispatch(clearMyAssetLocations());
    dispatch(downloadMyAssetLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setAssetLocationSearchText(''));
    dispatch(clearAssetLocations());
    dispatch(downloadAssetLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCertificationTypeSearchText(''));
    dispatch(clearCertificationTypes());
    dispatch(setCertificationTypeSelectedPageSize(20));
    dispatch(setCertificationTypeSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_TYPES));
    dispatch(downloadCertificationTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAssetAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/location/, 'location.id').replace(/category/, 'category.id');
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(`${ASSET_NEED_CERTIFICATION_FILTER_STRING}${text}`));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAssetAdvancedFilterPressed: () => {
    dispatch(setAssetTypeSearchText(''));
    dispatch(clearAssetTypes());
    dispatch(downloadAssetTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setAssetLocationSearchText(''));
    dispatch(clearAssetLocations());
    dispatch(downloadAssetLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCertificationTypeSearchText(''));
    dispatch(clearCertificationTypes());
    dispatch(setCertificationTypeSelectedPageSize(20));
    dispatch(setCertificationTypeSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_TYPES));
    dispatch(downloadCertificationTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAssetSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAssetSearchText(text));
      dispatch(clearAssets());
      await dispatch(downloadAssetsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAssetSortPressed: (orderBy) => {
    dispatch(setAssetSelectedOrderBy(orderBy));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setCertificationRequestDialogVisibility(false, ''));
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setCertificationRequestSelectedCategory(option));
      dispatch(change(RXFORM_CERTIFICATION_REQUEST, RXFIELD_CERTIFICATION_REQUEST_CATEGORY,
        option));
    } else {
      dispatch(setCertificationRequestSelectedCategory(''));
      dispatch(change(RXFORM_CERTIFICATION_REQUEST, RXFIELD_CERTIFICATION_REQUEST_CATEGORY, ''));
    }
  },
  onCertTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setCertificationRequestSelectedType(option.value));
      dispatch(change(RXFORM_CERTIFICATION_REQUEST, RXFIELD_CERTIFICATION_REQUEST_TYPE,
        option));
    } else {
      dispatch(setCertificationRequestSelectedType(''));
      dispatch(change(RXFORM_CERTIFICATION_REQUEST, RXFIELD_CERTIFICATION_REQUEST_TYPE, ''));
    }
  },
  onChangeAssetPage: (pageNo) => {
    dispatch(downloadAssetsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeAssetPageSize: (pageSize) => {
    dispatch(setAssetSelectedPageSize(pageSize));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeAssetTypeText: async (text) => {
    try {
      dispatch(setAssetTypeSearchText(text));
      dispatch(clearAssetTypes());
      await dispatch(downloadAssetTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCertTypeText: async (text) => {
    try {
      dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCertificationTypeSearchText(text));
      dispatch(clearCertificationTypes());
      await dispatch(downloadCertificationTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeLocationText: async (text) => {
    try {
      dispatch(setAssetLocationSearchText(text));
      dispatch(clearAssetLocations());
      await dispatch(downloadAssetLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeOwnerText: async (text) => {
    try {
      dispatch(setMyAssetLocationSearchText(text));
      dispatch(clearMyAssetLocations());
      await dispatch(downloadMyAssetLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onDownloadDocumentPressed: (tappedAssetId) => {
    dispatch(setCertificationRequestDialogVisibility(true, CERT_REQUEST_DIALOG_MODE_SELECT_FILE));
    dispatch(setAssetDocumentAdvancedFilterDialogSelectedFilterString(`asset.id=${tappedAssetId}`));
    dispatch(setAssetDocumentSearchText(''));
    dispatch(clearAssetDocuments());
    dispatch(setAssetDocumentSelectedPageSize(20));
    dispatch(setAssetDocumentSelectedOrderBy(INITIAL_ORDER_BY_ASSET_DOCUMENTS));
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onOwnerOptionSelected: (option) => {
    if (option) {
      dispatch(setCertificationRequestSelectedOwner(option.value));
      dispatch(change(RXFORM_CERTIFICATION_REQUEST, RXFIELD_CERTIFICATION_REQUEST_OWNER,
        option));
    } else {
      dispatch(setCertificationRequestSelectedOwner(''));
      dispatch(change(RXFORM_CERTIFICATION_REQUEST, RXFIELD_CERTIFICATION_REQUEST_OWNER, ''));
    }
  },
  onResetAssetAdvancedFilterPressed: () => {
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(
      ASSET_NEED_CERTIFICATION_FILTER_STRING,
    ));
    dispatch(clearAssets());
    dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async (selectedAssets) => {
    try {
      await dispatch(addCertificationRequestAsync(selectedAssets));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCertificationRequestPage);
