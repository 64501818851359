import { ORDER_SORT_ASCENDING, ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_ASSETS = '/ecertification/api/v1/Asset?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_ASSET = '/ecertification/api/v1/Asset/{id}';
export const REST_URL_ASSET_TYPES = '/ecertification/api/v1/AssetCategory?pageNumber=1&pageSize=20&orderBy=name asc&searchString={searchText}';
export const REST_URL_ASSET_LOCATIONS = '/ecertification/api/v1/AssetLocation?pageNumber=1&pageSize=20&orderBy=name asc&searchString={searchText}';
export const REST_URL_CERTIFICATION_TYPES = '/ecertification/api/v1/CertificationType?pageNumber=1&pageSize=20&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_CERTIFICATION_TYPE = '/ecertification/api/v1/CertificationType';
export const REST_URL_VIEW_DELETE_CERTIFICATION_TYPE = '/ecertification/api/v1/CertificationType/{id}';
export const REST_URL_ASSET_CERTIFICATES = '/ecertification/api/v1/AssetCertificate?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_ASSET_CERTIFICATE = '/ecertification/api/v1/AssetCertificate';
export const REST_URL_VIEW_DELETE_ASSET_CERTIFICATE = '/ecertification/api/v1/AssetCertificate/{id}';
export const REST_URL_ASSET_DOCUMENTS = '/ecertification/api/v1/AssetDocument?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_ASSET_DOCUMENT = '/ecertification/api/v1/AssetDocument';
export const REST_URL_VIEW_DELETE_ASSET_DOCUMENT = '/ecertification/api/v1/AssetDocument/{id}';
export const REST_URL_CHANGE_ASSET_REMINDER_FLAG = '/ecertification/api/v1/AssetReminderFlag';
export const REST_URL_ASSET_CHILDREN = '/ecertification/api/v1/Asset?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_ASSET_CHILD = '/ecertification/api/v1/AssetChildren';
export const REST_URL_DELETE_ASSET_CHILD = '/ecertification/api/v1/AssetChildren/{id}';
export const REST_URL_PARENTLESS_ASSETS = '/ecertification/api/v1/Asset?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=assetParent=$NULL$';
export const REST_URL_CERTIFICATION_REQUESTS = '/ecertification/api/v1/CertificationRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_CERTIFICATION_REQUEST = '/ecertification/api/v1/CertificationRequest';
export const REST_URL_VIEW_CERTIFICATION_REQUEST = '/ecertification/api/v1/CertificationRequest/{id}';
export const REST_URL_CERTIFICATION_REQUEST_APPROVALS = '/ecertification/api/v1/CertificationRequestApproval/{id}';
export const REST_URL_CHECK_CERTIFICATION_REQUEST_APPROVAL_PERMISSION = '/approval/api/v1/MyApproval?resultType=active&filterString=referenceId={referenceId}';
export const REST_URL_MY_ASSET_LOCATIONS = '/ecertification/api/v1/MyLocation';
export const REST_URL_APPROVE_REJECT_CERTIFICATION_REQUEST = '/approval/api/v1/ApprovalAction';
export const REST_URL_SCHEDULE_ASSET = '/ecertification/api/v1/AssetScheduling';
export const REST_URL_ADD_ASSET_SCREENING = '/ecertification/api/v1/AssetScreening';
export const REST_URL_CERTIFICATION_MY_APPROVALS = '/approval/api/v1/MyApproval?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}';

export const ROUTE_NAME_E_CERTIFICATION = '/e-certification';
export const ROUTE_NAME_ASSET = '/asset';
export const ROUTE_NAME_ASSET_DETAIL = '/asset/:id';
export const ROUTE_NAME_CERTIFICATION_TYPE = '/certification-type';
export const ROUTE_NAME_CERTIFICATION_REQUEST = '/certification-request';
export const ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL = '/certification-request/:id';
export const ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING = '/certification-schedule-setting';
export const ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL = '/certification-schedule-setting/:id';
export const ROUTE_NAME_ASSET_SCREENING = '/asset-screening';
export const ROUTE_NAME_CERTIFICATION_MY_APPROVAL = '/my-approval';

export const RXFORM_ASSET_DOCUMENT_TAB = 'assetDocumentTab';
export const RXFORM_ASSET_CHILD_TAB = 'assetChildTab';
export const RXFORM_CERTIFICATION_TYPE = 'certificationTypePage';
export const RXFORM_CERTIFICATION_REQUEST = 'certificationRequestPage';
export const RXFORM_SET_SCHEDULE_DIALOG = 'setScheduleDialog';
export const RXFORM_ASSET_CERTIFICATE = 'assetCertificate';
export const RXFORM_ASSET_SCREENING = 'assetScreeningPage';
export const RXFORM_CERTIFICATION_MY_APPROVAL = 'certificationMyApprovalPage';

export const RXFIELD_ASSET_SOURCE_ID = 'sourceId';
export const RXFIELD_ASSET_ASSET_DESCRIPTION = 'assetDescription';
export const RXFIELD_ASSET_PART_CODE = 'partCode';
export const RXFIELD_ASSET_SERIAL_NUMBER = 'serialNumber';
export const RXFIELD_ASSET_ASSET_NUMBER = 'assetNumber';
export const RXFIELD_ASSET_ASSET_TYPE = 'category';
export const RXFIELD_ASSET_LOCATION = 'location';
export const RXFIELD_ASSET_CERTIFICATION_TYPE = 'certificationType';
export const RXFIELD_ASSET_CERTIFICATION_CATEGORY = 'certificationCategory';
export const RXFIELD_ASSET_CERTIFICATE_START_DATE = 'certificateStartDate';
export const RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE = 'certificateExpirationDate';
export const RXFIELD_ASSET_CERTIFICATION_SCHEDULE_DATE = 'certificationScheduledDate';
export const RXFIELD_ASSET_LAST_PROGRESS = 'certificateResultType';
export const RXFIELD_ASSET_ISSUER = 'certificateSubmitter';
export const RXFIELD_ASSET_OWNER = 'owner';
export const RXFIELD_CERTIFICATION_TYPE_NAME = 'name';
export const RXFIELD_CERTIFICATION_TYPE_DESCRIPTION = 'description';
export const RXFIELD_ASSET_DOCUMENT_FILE = 'file';
export const RXFIELD_ASSET_CHILD_CHILDREN = 'children';
export const RXFIELD_CERTIFICATION_REQUEST_REQUEST_NUMBER = 'requestNumber';
export const RXFIELD_CERTIFICATION_REQUEST_PIC = 'pic';
export const RXFIELD_CERTIFICATION_REQUEST_OWNER = 'assetLocation';
export const RXFIELD_CERTIFICATION_REQUEST_CATEGORY = 'certificationCategory';
export const RXFIELD_CERTIFICATION_REQUEST_TYPE = 'certificationType';
export const RXFIELD_CERTIFICATION_REQUEST_ASSET = 'assets';
export const RXFIELD_CERTIFICATION_REQUEST_APPROVAL_STATUS = 'approvalStatus';
export const RXFIELD_CERTIFICATION_SCHEDULE_SETTING_DATE = 'scheduledDate';
export const RXFIELD_CERTIFICATION_SCHEDULE_SETTING_ISSUE_DATE = 'issuedDate';
export const RXFIELD_CERTIFICATION_SCHEDULE_SETTING_EXPIRED_DATE = 'expiredDate';
export const RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE = 'resultType';
export const RXFIELD_CERTIFICATION_SCHEDULE_SETTING_REMARK = 'remark';
export const RXFIELD_CERTIFICATION_SCHEDULE_SETTING_FILE = 'file';
export const RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE = 'certificationType';
export const RXFIELD_ASSET_SCREENING_CERTIFICATION_STATUS = 'certificationStatus';

export const RXSTATE_ASSETS = 'assets';
export const RXSTATE_ASSET_PAGE = 'uiAsset';
export const RXSTATE_CERTIFICATION_TYPES = 'certificationTypes';
export const RXSTATE_CERTIFICATION_TYPE_PAGE = 'uiCertificationType';
export const RXSTATE_CERTIFICATION_REQUESTS = 'certificationRequests';
export const RXSTATE_CERTIFICATION_REQUEST_PAGE = 'uiCertificationRequest';
export const RXSTATE_CERTIFICATION_MY_APPROVALS = 'certificationMyApprovals';
export const RXSTATE_CERTIFICATION_MY_APPROVAL_PAGE = 'uiCertificationMyApproval';

export const INITIAL_ORDER_BY_ASSETS = `sourceId ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_ASSET_CERTIFICATES = `issuedDate ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_ASSET_DOCUMENTS = `createdDate ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_ASSET_CHILDREN = `sourceId ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_PARENTLESS_ASSETS = `sourceId ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_CERTIFICATION_TYPES = `createdDate ${ORDER_SORT_ASCENDING}`;
export const INITIAL_ORDER_BY_CERTIFICATION_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_CERTIFICATION_MY_APPROVALS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_E_CERTIFICATION = 'MENUID_E_CERTIFICATION';
export const MENUID_E_CERTIFICATION_ASSET = 'MENUID_E_CERTIFICATION_ASSET';
export const MENUID_E_CERTIFICATION_CERTIFICATION_TYPE = 'MENUID_E_CERTIFICATION_CERTIFICATION_TYPE';
export const MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST = 'MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST';
export const MENUID_E_CERTIFICATION_CERTIFICATION_SCHEDULE_SETTING = 'MENUID_E_CERTIFICATION_CERTIFICATION_SCHEDULE_SETTING';
export const MENUID_E_CERTIFICATION_ASSET_SCREENING = 'MENUID_E_CERTIFICATION_ASSET_SCREENING';
export const MENUID_E_CERTIFICATION_MY_APPROVAL = 'MENUID_E_CERTIFICATION_MY_APPROVAL';

export const CERT_CATEGORY_NEW = 'New';
export const CERT_CATEGORY_RECERTIFICATION = 'Recertification';

export const CERT_STATUS_PENDING = 'Pending';
export const CERT_STATUS_WAITING_FOR_APPROVAL = 'WaitingForApproval';
export const CERT_STATUS_APPROVED = 'Approved';
export const CERT_STATUS_REJECTED = 'Rejected';

export const CERTIFICATION_STATUS_NEED = 'NeedCertification';
export const CERTIFICATION_STATUS_NO_NEED = 'NoNeedCertification';
export const CERTIFICATION_STATUS_UNDEFINED = 'Undefined';

export const CERT_CATEGORIES = [CERT_CATEGORY_NEW, CERT_CATEGORY_RECERTIFICATION];
export const CERT_REQUEST_STATUSES = [CERT_STATUS_WAITING_FOR_APPROVAL, CERT_STATUS_APPROVED,
  CERT_STATUS_REJECTED];
export const RESULT_TYPES = ['Permanent', 'Temporary'];
export const CERTIFICATION_STATUSES = [CERTIFICATION_STATUS_NEED,
  CERTIFICATION_STATUS_NO_NEED, CERTIFICATION_STATUS_UNDEFINED];

export const CERT_REQUEST_DIALOG_MODE_ADD_ASSET = 'addAsset';
export const CERT_REQUEST_DIALOG_MODE_SELECT_FILE = 'selectFile';
export const CERT_SCHEDULE_SETTING_DIALOG_MODE_SET = 'setSchedule';
export const CERT_SCHEDULE_SETTING_DIALOG_MODE_ADD = 'addCertificate';
export const CERT_SCHEDULE_SETTING_DIALOG_MODE_EDIT = 'editCertificate';

export const ASSET_NEED_CERTIFICATION_FILTER_STRING = 'certificationStatus=NeedCertification';

export const MY_APPROVAL_TYPES = [
  'All', 'Active', 'Past', 'Future',
];
