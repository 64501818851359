import React, {
  forwardRef, useEffect, useState, useRef,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { withRouter, useRouteMatch, useHistory } from 'react-router-dom';
import {
  Badge, Divider, Fab, IconButton, Grid, Menu, MenuItem, Typography, withStyles,
} from '@material-ui/core';
import {
  Add, ArrowBack, ArrowUpward, Clear, CloudDownload, MoreHoriz, Notifications, Search,
  SkipNextOutlined, SkipPreviousOutlined,
} from '@material-ui/icons';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Refresh } from 'mdi-material-ui';
import {
  COLOR_ACCENT, COLOR_BACKGROUND, COLOR_DANGEROUS, COLOR_DISABLED_ROW, COLOR_ICON_LIGHTER,
  COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TERTIARY, COLOR_TEXT_BLACK_SECONDARY, COLOR_TEXT_PRIMARY,
  DISABLE_EN, DISABLE_ID, DRAWER_WIDTH, ENABLE_EN, ENABLE_ID, FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM,
  FUNCTIONAL_PAGE_CONTENT_MODE_FIELD, FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_FOOTER,
  FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_HEADER, PAGE_MODE_CREATE, PAGE_MODE_EDIT,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW, PAGE_SIZE_OPTIONS, RXFIELD_CREATED_BY,
  RXFIELD_CREATED_DATE, RXFIELD_ID, RXFIELD_LAST_MODIFIED_BY, RXFIELD_LAST_MODIFIED_DATE,
  RXFIELD_STATUS, STATUS_DISABLED,
  STATUS_ENABLED,
} from '../../constant';
import { isEvenNumber, onSortPress } from '../../helper/helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import { MoreMenuShape, TableColumnShape, TotalCountShape } from '../../type';
import AccentButton from '../accent-button';
import BasePage from '../base-page';
import SectionTitle from '../section-title';
import TotalCountSection from '../total-count-section';
import AddEditDialog from './add-edit-dialog';
import AdvancedFilterDialog from './advanced-filter-dialog';

const filterIcon = require('../../asset/filter.png');

const styles = (theme) => ({
  headerField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  outlineSendButton: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
    marginLeft: 10,
  },
  title: {
    color: COLOR_TEXT_PRIMARY,
    fontWeight: 700,
  },
  editDetailHeaderField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: '8px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  primaryButton: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  primaryButtonContainer: {
    padding: '4px',
    marginRight: '12px',
    backgroundColor: COLOR_BACKGROUND,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: COLOR_ACCENT,
  },
  menuPathText: {
    color: COLOR_PRIMARY,
    fontWeight: 400,
    marginRight: 4,
  },
  lastMenuPathText: {
    color: COLOR_PRIMARY,
    fontWeight: 700,
  },
  editDetailTitle: {
    color: COLOR_TEXT_PRIMARY,
    marginRight: 35,
    fontWeight: 700,
  },
  divider: {
    backgroundColor: COLOR_SECONDARY,
    marginBottom: 12,
  },
  redButton: {
    color: COLOR_DANGEROUS,
    marginRight: 7,
  },
  fieldContainer: {
    background: COLOR_BACKGROUND,
    padding: '13px 35px 13px 35px',
    borderRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    justifyContent: 'space-between',
    marginBottom: 80,
  },
  multipleFloatingContainer: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 35px 12px 35px',
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 5,
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.16)',
    height: 60,
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
    width: '100%',
    zIndex: 1,
    '&:hover': {
      backgroundColor: COLOR_BACKGROUND,
    },
  },
  multipleFloatingShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
  leftFloatingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  floatingContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 35px 12px 35px',
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 5,
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.16)',
    height: 60,
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
    width: '100%',
    zIndex: 1,
    '&:hover': {
      backgroundColor: COLOR_BACKGROUND,
    },
  },
  filterButtonContainer: {
    marginRight: '20px',
  },
  badge: {
    margin: theme.spacing(0.5),
    width: 10,
  },
  saveButtonWrapper: {
    marginLeft: 10,
    padding: '2.4px 4px 2.4px 10px',
  },
  saveButtonLabel: {
    width: '0%',
  },
  moreButton: {
    padding: '0px',
  },
  buttonOutline: {
    fontSize: '12px',
    padding: '0px 2px',
  },
  tableToolbarContainer: {
    marginBottom: '-16px',
  },
  filterActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '-45px',
    padding: '6px 0px 10px 20px',
  },
});

const newStyles = {
  tableBaseIcon: { color: COLOR_ICON_LIGHTER },
  tableIcon: { fontSize: '20px', color: COLOR_ICON_LIGHTER },
  tableSortIcon: { fontSize: '12px', color: COLOR_TEXT_BLACK_SECONDARY },
  primaryIcon: { color: COLOR_PRIMARY },
};

const ITEM_HEIGHT = 48;

const FunctionalPage = ({
  customActions, data, stepContent, stepTitle, moreMenus, filterColumns, filterSections,
  selectionActions, tableColumns, totalCountSectionData,
  addingEditing, createPermission, cropping, customDeleteButtonMenuCaption, deletePermission,
  disableCreate, disableDelete, disableEdit, disableView, disableSaveSubmit, disableActions,
  downloading, downloadingDeleting, drawerVisibility, editPermission, enablingDisabling,
  savePermission, saving, selection, sendingNotification, sendNotificationPermission,
  useBackButtonTableMode, useCreateButtonInViewMode, useNotificationCount, useSendAllNotification,
  useTotalCountCustomLastField, useTotalCountSection, useTwoColumnFilterDialog, useFullWidth,
  usefullWidthDialog,
  handleSubmit, onAdvancedFilterPressed, onAppear, onAppearWithDetailRoute,
  onApplyAdvancedFilterPressed, onBackPressed, onCancelAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onConfirmDeletePressed, onCreatePressed, onCreateNewPressed,
  onDeletePressed, onDownloadPressed, onEditPressed, onFunctionalPageAdvancedFilterPressed,
  onFunctionalPageAppear, onFunctionalPageBackPressed, onFunctionalPageEditPressed,
  onFunctionalPageViewPressed, onFunctionalPageWithDetailRouteAppear, onMorePressed,
  onSelectionActionPressed, onOpenSendNotificationDialog, onRefresh, onResetAdvancedFilterPressed,
  onSavePressed, onSelectionChange, onSearchBarTextChanged, onSendNotificationPressed,
  onSortPressed, onSubmitPressed, onViewPressed, renderCustomUpperButton, renderCustomFabComponent,
  currentPage, totalCount, selectedPageSize, children, customSelectionActionButton,
  totalCountSectionCustomLastField, classes, history, menuPath, contentMode,
  customSelectionActionButtonPosition, pageMode,
  totalCountSectionMode, rowStyle,
  addDialogHeaderContainerStyle, addDialogSubmitButtonCaption, createNewButtonCaption,
  createPermissionName, deleteButtonCaption, deletePermissionName, detailRoute, detailTitle,
  editButtonCaption, editPermissionName, filterString, savePermissionName, searchBarText,
  sendNotificationPermissionName, orderBy, tappedId, title, backToUrl, onNavigateToRedirectFrom,
}) => {
  const [initSearchText, setInitSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [newData, setNewData] = useState([]);

  const isDetailRoute = useRouteMatch(detailRoute);
  const detailIdParam = isDetailRoute?.params?.id;

  const routeHistory = useHistory();

  useEffect(() => {
    onFunctionalPageWithDetailRouteAppear(detailIdParam);
    onAppearWithDetailRoute(detailIdParam);
    // eslint-disable-next-line
  }, [detailIdParam]);

  useEffect(() => {
    onFunctionalPageAppear(history);
    onAppear(pageMode);
    // eslint-disable-next-line
  }, [history, onFunctionalPageAppear, onAppear]);

  useEffect(() => {
    if (searchBarText) {
      setInitSearchText(encodeURIComponent(searchBarText));
    } else {
      setInitSearchText('');
    }
  }, [searchBarText]);

  useEffect(() => {
    const transformedData = data.map((x) => ({
      ...x,
      tableData: {
        ...x.tableData,
        checked: (selectedRows.some((y) => y?.id === x?.id)) || false,
      },
    }));
    setNewData(transformedData);
  }, [data, selectedRows]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const anchorSelectedEl = useRef();
  const [openSelectedMenu, setOpenSelectedMenu] = useState(false);
  const [moreMenuId, setMoreMenuId] = useState('');
  const columns = tableColumns.length > 0
    ? tableColumns.map((x) => ({ ...x, customSort: () => null })) : [];

  const onCancelPress = () => {
    onCancelPressed(history);
    onFunctionalPageBackPressed();
  };

  const onBackPress = () => {
    if (backToUrl) {
      onNavigateToRedirectFrom(routeHistory, backToUrl);
    } else if (useBackButtonTableMode && (pageMode === PAGE_MODE_TABLE
      || (contentMode === FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM && pageMode !== PAGE_MODE_CREATE))) {
      history.goBack();
      onBackPressed(history);
      if (contentMode === FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM) { onCancelPress(); }
    } else {
      if (pageMode === PAGE_MODE_EDIT || pageMode === PAGE_MODE_CREATE) { onCancelPress(); }
      onFunctionalPageBackPressed();
    }
  };

  const onEditPress = (id) => {
    onEditPressed(id);
    onFunctionalPageEditPressed(id);
  };

  const onCreateNewPress = () => {
    onCreatePressed(history);
    onCreateNewPressed(contentMode, createNewButtonCaption, useCreateButtonInViewMode);
  };

  const onCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMoreMenu = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setMoreMenuId(rowData.id);
    onMorePressed(rowData.id);
  };

  const onCloseSelectActionMenu = () => {
    setOpenSelectedMenu(false);
  };

  const onOpenSelectActionMenu = () => {
    setOpenSelectedMenu(true);
  };

  const onSubSelectActionMenuPress = (itemFunction) => {
    itemFunction();
    onCloseSelectActionMenu();
  };

  const onSendNotificationPress = () => {
    onOpenSendNotificationDialog();
    onSendNotificationPressed();
  };

  const onSubMoreMenuPress = (itemFunction, id) => {
    itemFunction(id);
    onCloseMoreMenu();
  };

  const onViewPress = (id) => {
    onViewPressed(id, history);
    onFunctionalPageViewPressed(id);
  };

  const renderAdditionalField = () => {
    if (PAGE_MODE_VIEW) {
      return (
        <div>
          <SectionTitle title={LocalizedString.common.labelBasicInformation} />

          <Grid container spacing={3}>
            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_ID}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderId}
                  label={LocalizedString.common.placeholderId}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_CREATED_BY}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderCreatedBy}
                  label={LocalizedString.common.placeholderCreatedBy}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_CREATED_DATE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderCreatedDate}
                  label={LocalizedString.common.placeholderCreatedDate}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_STATUS}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderStatus}
                  label={LocalizedString.common.placeholderStatus}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_LAST_MODIFIED_BY}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderLastModifiedBy}
                  label={LocalizedString.common.placeholderLastModifiedBy}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_LAST_MODIFIED_DATE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.common.placeholderLastModifiedDate}
                  label={LocalizedString.common.placeholderLastModifiedDate}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return null;
  };

  const menus = moreMenus.map((x) => {
    const isEnableButton = x.caption === ENABLE_ID || x.caption === ENABLE_EN;
    const isDisableButton = x.caption === DISABLE_ID || x.caption === DISABLE_EN;
    const found = newData.length ? newData.find((y) => y.id === moreMenuId) : null;
    const disableEnableButton = isEnableButton ? found?.status === STATUS_ENABLED
      : false;
    const disableDisableButton = isDisableButton ? found?.status === STATUS_DISABLED
      : false;
    const disableEnInButton = disableEnableButton || disableDisableButton
    || (editPermissionName && !editPermission) || enablingDisabling;
    const disabling = typeof x.disabled === 'boolean' ? x.disabled : x.disabled(found);

    return ({
      ...x,
      caption: typeof x.caption === 'string' ? x.caption : `${x.caption(found)}`,
      disabled: isEnableButton || isDisableButton ? !!disabling || disableEnInButton : !!disabling,
      disableMultiple: isEnableButton || isDisableButton
        ? x.disableMultiple || disableEnInButton : x.disableMultiple,
      onPress: () => { onMorePressed(moreMenuId); x.onPress(moreMenuId, found); },
    });
  });

  const moreMenuList = [
    {
      caption: LocalizedString.common.buttonCaptionView,
      disabled: false,
      onPress: () => onViewPress(moreMenuId),
    },
    {
      caption: LocalizedString.common.labelEdit,
      disabled: disableEdit || (editPermissionName && !editPermission),
      onPress: () => onEditPress(moreMenuId),
    },
    {
      caption: customDeleteButtonMenuCaption ? (deleteButtonCaption
        || LocalizedString.common.labelCancel) : LocalizedString.common.labelDelete,
      disabled: disableDelete || (deletePermissionName && !deletePermission),
      onPress: () => onDeletePressed(moreMenuId),
    },
    ...menus,
  ];

  const renderMoreMenu = (rowData) => (moreMenuList.some((x) => !x.disabled
  && x.caption !== LocalizedString.common.labelCancel)) && (
  <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      size="small"
      onClick={(event) => {
        onOpenMoreMenu(event, rowData);
        onSelectionActionPressed(selectedRows);
      }}
      className={classes.moreButton}
    >
      <MoreHoriz style={newStyles.primaryIcon} fontSize="small" />
    </IconButton>

    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onCloseMoreMenu}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 200,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      {moreMenuList.map((item) => {
        if (!item.disabled && item.caption !== LocalizedString.common.labelCancel) {
          return (
            <MenuItem
              key={item.caption}
              onClick={() => onSubMoreMenuPress(item.onPress, tappedId)}
            >
              {item.caption}
            </MenuItem>
          );
        }
        return null;
      })}
    </Menu>
  </div>
  );

  const selectActionMenuList = [
    {
      caption: customDeleteButtonMenuCaption ? (deleteButtonCaption
        || LocalizedString.common.labelCancel) : LocalizedString.common.labelDelete,
      disabled: disableDelete || (deletePermissionName && !deletePermission),
      onPress: () => onDeletePressed(tappedId),
    },
    ...menus,
  ];

  const renderSelectActionMenu = () => (selectActionMenuList.some((x) => !x.disableMultiple
  && x.caption !== LocalizedString.common.labelCancel)) && (
    <div>
      {selectedRows.length > 0 && (
      <div ref={anchorSelectedEl}>
        <AccentButton
          variant="outlined"
          caption={LocalizedString.common.buttonCaptionAction}
          disabled={addingEditing || downloadingDeleting}
          onClick={() => {
            onOpenSelectActionMenu();
            onSelectionActionPressed(selectedRows);
          }}
          size="small"
          style={classes.buttonOutline}
        />
      </div>
      )}

      <Menu
        id="select-action-menu"
        anchorEl={() => anchorSelectedEl.current}
        keepMounted
        open={openSelectedMenu}
        onClose={onCloseSelectActionMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200,
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        {selectActionMenuList.map((item) => {
          if (!item.disableMultiple && item.caption !== LocalizedString.common.labelCancel) {
            return (
              <MenuItem key={item.caption} onClick={() => onSubSelectActionMenuPress(item.onPress)}>
                {item.caption}
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
    </div>
  );

  const renderFloatingButton = () => {
    if (pageMode === PAGE_MODE_VIEW && useNotificationCount) {
      return (
        <Fab
          className={clsx(classes.multipleFloatingContainer,
            { [classes.multipleFloatingShift]: drawerVisibility })}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        >

          {sendNotificationPermissionName && sendNotificationPermission && (
          <div className={classes.leftFloatingWrapper}>
            <AccentButton
              startIcon={<Notifications />}
              variant="outlined"
              disabled={addingEditing || sendingNotification}
              disableElevation
              caption={LocalizedString.common.buttonCaptionSendNotification}
              onClick={onSendNotificationPress}
              size="small"
            />
          </div>
          )}

          <div>
            {(!disableDelete && deletePermissionName && deletePermission)
            || (!disableDelete && !deletePermissionName) ? (
              <AccentButton
                variant="text"
                className={classes.redButton}
                disabled={downloadingDeleting || addingEditing}
                disableElevation
                caption={deleteButtonCaption}
                onClick={() => onDeletePressed(tappedId)}
                size="small"
              />
              ) : null}

            {(!disableEdit && editPermissionName && editPermission)
            || (!disableEdit && !editPermissionName) ? (
              <AccentButton
                disabled={addingEditing || downloadingDeleting}
                disableElevation
                caption={editButtonCaption}
                onClick={() => onEditPress(tappedId)}
                size="small"
              />
              ) : null}
          </div>
        </Fab>
      );
    }
    if (pageMode === PAGE_MODE_EDIT) {
      return (
        <Fab
          className={classes.floatingContainer}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        >
          <AccentButton
            variant="text"
            className={classes.redButton}
            disabled={addingEditing}
            disableElevation
            caption={LocalizedString.common.buttonCaptionCancel}
            onClick={onCancelPress}
            size="small"
          />

          <AccentButton
            loading={addingEditing}
            disableElevation
            caption={LocalizedString.common.buttonCaptionSave}
            onClick={handleSubmit(async (values) => {
              await onSavePressed(values);
              onCancelPress();
            })}
            disabled={cropping || disableSaveSubmit}
            size="small"
          />
        </Fab>
      );
    }
    return (
      <Fab
        className={classes.floatingContainer}
        disableFocusRipple
        disableTouchRipple
        disableRipple
      >
        {renderCustomFabComponent()}

        {(!disableDelete && deletePermissionName && deletePermission)
        || (!disableDelete && !deletePermissionName) ? (
          <AccentButton
            variant="text"
            className={classes.redButton}
            disabled={downloadingDeleting || addingEditing}
            disableElevation
            caption={deleteButtonCaption}
            onClick={() => onDeletePressed(tappedId)}
            size="small"
          />
          ) : null}

        {(!disableEdit && editPermissionName && editPermission)
        || (!disableEdit && !editPermissionName) ? (
          <AccentButton
            disabled={addingEditing || downloadingDeleting}
            disableElevation
            caption={editButtonCaption}
            onClick={() => onEditPress(tappedId)}
            size="small"
          />
          ) : null}
      </Fab>
    );
  };

  const renderCreateNewButton = () => (
    <div>
      {((!disableCreate && createPermissionName && createPermission) || (!disableCreate
      && !createPermissionName)) && (
      <AccentButton
        onClick={() => onCreateNewPress()}
        endIcon={<Add />}
        caption={createNewButtonCaption}
        size="small"
      />
      )}

      {(disableCreate && useSendAllNotification
      && sendNotificationPermissionName && sendNotificationPermission) && (
        <AccentButton
          onClick={onSendNotificationPress}
          startIcon={<Notifications />}
          caption={LocalizedString.common.buttonCaptionSendNotification}
          size="small"
        />
      )}

      {(!disableCreate && useSendAllNotification
      && sendNotificationPermissionName && sendNotificationPermission) && (
      <AccentButton
        onClick={onSendNotificationPress}
        startIcon={<Notifications />}
        caption={LocalizedString.common.buttonCaptionSendNotification}
        variant="outlined"
        className={classes.outlineSendButton}
        size="small"
      />
      )}

      {savePermissionName && savePermission && (
      <AccentButton
        onClick={onDownloadPressed}
        startIcon={<CloudDownload />}
        caption=""
        disabled={downloading || addingEditing || downloadingDeleting || saving}
        loading={saving}
        variant="outlined"
        classes={{
          startIcon: classes.saveButtonWrapper,
          label: classes.saveButtonLabel,
          root: classes.saveButtonWrapper,
        }}
        size="small"
      />
      )}

      {renderCustomUpperButton()}
    </div>
  );

  const renderMenuPath = () => {
    const { childTitle, grandChildTitle, parentTitle } = menuPath;

    if (childTitle || grandChildTitle) {
      return (
        <div className={classes.rowContainer}>
          <Typography variant="body2" className={classes.menuPathText}>
            {childTitle && grandChildTitle ? `${parentTitle} / ${childTitle} / ` : `${parentTitle} / `}
          </Typography>
          <Typography variant="body2" className={classes.lastMenuPathText}>
            {childTitle || grandChildTitle}
          </Typography>
        </div>
      );
    }
    return (<Typography variant="body2" className={classes.lastMenuPathText}>{parentTitle}</Typography>);
  };

  const TitleWithBackButton = (
    <div className={classes.editDetailHeaderField}>
      <div className={classes.rowContainer}>
        {renderMenuPath()}
      </div>
      <div className={classes.rowContainer}>
        <IconButton onClick={onBackPress} className={classes.primaryButtonContainer}>
          <ArrowBack className={classes.primaryButton} />
        </IconButton>
        <Typography variant="h5" className={classes.editDetailTitle}>{detailIdParam ? detailTitle : title}</Typography>
      </div>
    </div>
  );

  if (!detailIdParam && pageMode === PAGE_MODE_TABLE) {
    let actions = [];
    if (disableActions) {
      actions = [
        {
          icon: () => (<Refresh style={newStyles.tableIcon} />),
          tooltip: LocalizedString.common.labelRefreshData,
          isFreeAction: true,
          onClick: () => onRefresh(selectedPageSize),
        },
      ];
    } else {
      const defaultActions = [
        {
          icon: () => (<Refresh style={newStyles.tableIcon} />),
          tooltip: LocalizedString.common.labelRefreshData,
          isFreeAction: true,
          onClick: () => onRefresh(selectedPageSize),
        },
        {
          position: 'row',
          action: (rowData) => ({
            icon: () => (<AccentButton caption={LocalizedString.common.buttonCaptionView} variant="outlined" size="small" style={classes.buttonOutline} />),
            onClick: (event, row) => onViewPress(row.id),
            position: 'row',
            hidden: !rowData.hideContextMenu,
          }),
        },
        {
          position: 'row',
          iconProps: { fontSize: 'small' },
          action: (rowData) => ({
            icon: () => renderMoreMenu(rowData),
            hidden: rowData.hideContextMenu || false,
            onClick: (event, rowdata) => onMorePressed(rowdata.id),
            position: 'row',
          }),
        },
      ];

      if (selectedRows.length > 0) {
        actions = [...actions, ...selectionActions];
      } else {
        actions = [...actions, ...(customActions.length ? customActions : defaultActions)];
      }
    }
    const rowPaddingHorizontal = selection ? 8 : 16;
    const rowPadding = disableActions ? `4px ${rowPaddingHorizontal}px` : `0px ${rowPaddingHorizontal}px`;

    return (
      <BasePage onConfirmPressed={onConfirmDeletePressed}>
        <div className={classes.headerField}>
          {useBackButtonTableMode ? TitleWithBackButton
            : (<Typography variant="h5" className={classes.title}>{title}</Typography>)}

          {renderCreateNewButton()}
        </div>
        <Divider className={classes.divider} />

        <MaterialTable
          key={selectedPageSize}
          title={null}
          isLoading={downloading}
          searchText={decodeURIComponent(initSearchText)}
          onSearchChange={(text) => {
            setInitSearchText(encodeURIComponent(text));
            onSearchBarTextChanged(encodeURIComponent(text));
          }}
          onChangePage={(page) => onChangePage(page)}
          onChangeRowsPerPage={(pageSize) => onChangePageSize(pageSize)}
          onOrderChange={(orderedColumnId, orderDirection) => onSortPress(orderedColumnId,
            orderDirection, orderBy, columns, onSortPressed)}
          onRowClick={disableActions && !disableView
            ? (event, rowData) => onViewPress(rowData.id) : undefined}
          columns={columns}
          data={newData}
          totalCount={totalCount}
          page={currentPage > 0 ? currentPage - 1 : currentPage}
          actions={actions}
          options={{
            headerStyle: {
              backgroundColor: COLOR_BACKGROUND,
              padding: selection ? '0px' : '4px 16px',
              fontSize: '12px',
              borderTop: `1px solid ${COLOR_SECONDARY}`,
              borderBottom: `1px solid ${COLOR_SECONDARY}`,
              whiteSpace: 'nowrap',
            },
            searchFieldStyle: {
              border: `1px solid ${COLOR_SECONDARY}`,
              borderRadius: 2,
              fontSize: '12px',
            },
            pageSize: selectedPageSize,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            actionsColumnIndex: selection ? 2 : 1,
            doubleHorizontalScroll: filterColumns.length > 0,
            draggable: false,
            showEmptyDataSourceMessage: !!(newData.length === 0 && !downloading),
            emptyRowsWhenPaging: false,
            debounceInterval: 2000,
            showTitle: false,
            searchText: decodeURIComponent(initSearchText),
            padding: 0,
            rowStyle: rowStyle || ((rowData, index) => {
              const newIndex = index + 1;
              const isEven = isEvenNumber(newIndex);
              const rowBgColor = isEven ? COLOR_TERTIARY : COLOR_BACKGROUND;
              return ({
                backgroundColor: rowData.status === STATUS_DISABLED
                  ? COLOR_DISABLED_ROW : rowBgColor,
                padding: rowPadding,
              });
            }),
            selection: selection ? true : selectionActions.length > 0,
            selectionProps: (rowData) => ({
              checked: rowData?.tableData?.checked || false,
              style: { padding: '0px 0px 0px 8px' },
            }),
            showTextRowsSelected: false,
            cellStyle: {
              padding: rowPadding,
              fontSize: '12px',
              borderTop: `1px solid ${COLOR_SECONDARY}`,
              borderBottom: `1px solid ${COLOR_SECONDARY}`,
            },
          }}
          components={{
            Toolbar: (props) => (
              <div className={classes.tableToolbarContainer}>
                <MTableToolbar {...props} />
                <div className={classes.filterActionContainer}>
                  {filterColumns.length > 0 ? (
                    <div className={classes.filterButtonContainer}>
                      <Badge color="error" badgeContent=" " invisible={!filterString} classes={{ anchorOriginTopRightRectangle: classes.badge }}>
                        <AccentButton
                          variant="outlined"
                          caption={LocalizedString.common.buttonCaptionAdvancedFilter}
                          endIcon={<img alt="filter" src={filterIcon} width={12} height={12} />}
                          disabled={addingEditing || downloadingDeleting}
                          onClick={() => {
                            onAdvancedFilterPressed();
                            onFunctionalPageAdvancedFilterPressed();
                          }}
                          size="small"
                          style={classes.buttonOutline}
                        />

                        {selectedRows.length > 0 && customSelectionActionButtonPosition
                        === FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_HEADER
                          ? customSelectionActionButton : <div />}
                      </Badge>
                    </div>
                  ) : <div style={{ height: '40px' }} />}
                  {selectedRows.length > 0 && !customSelectionActionButton
                  && renderSelectActionMenu()}
                </div>
              </div>
            ),
          }}
          onSelectionChange={(rows) => {
            setSelectedRows(rows);
            onSelectionChange(rows);
          }}
          icons={{
            Search: forwardRef((props, ref) => (
              <Search
                {...props}
                ref={ref}
                style={newStyles.tableIcon}
              />
            )),
            ResetSearch: forwardRef((props, ref) => (
              <Clear
                {...props}
                ref={ref}
                style={newStyles.tableIcon}
              />
            )),
            SortArrow: forwardRef((props, ref) => (
              <ArrowUpward
                {...props}
                ref={ref}
                style={newStyles.tableSortIcon}
              />
            )),
            FirstPage: forwardRef((props, ref) => (
              <SkipPreviousOutlined
                {...props}
                ref={ref}
                style={newStyles.tableBaseIcon}
              />
            )),
            LastPage: forwardRef((props, ref) => (
              <SkipNextOutlined
                {...props}
                ref={ref}
                style={newStyles.tableBaseIcon}
              />
            )),
          }}
          style={{ border: `1px solid ${COLOR_SECONDARY}` }}
          localization={{
            header: { actions: LocalizedString.common.labelAction },
          }}
        />

        {!disableCreate && (
        <AddEditDialog
          submitting={addingEditing}
          onSubmitPressed={onSubmitPressed}
          onCancelPressed={onCancelPress}
          handleSubmit={handleSubmit}
          disabled={cropping || disableSaveSubmit}
          stepContent={stepContent}
          stepTitle={stepTitle}
          headerContainerStyle={addDialogHeaderContainerStyle}
          usefullWidthDialog={usefullWidthDialog}
          buttonCaption={addDialogSubmitButtonCaption}
        >
          {children}
        </AddEditDialog>
        )}

        {filterColumns.length > 0 && (
          <AdvancedFilterDialog
            downloading={downloading}
            filterColumns={filterColumns}
            filterSections={filterSections}
            onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
            onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
            onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
            useTwoColumnFilterDialog={useTwoColumnFilterDialog}
          />
        )}

        {selectedRows.length > 0 && customSelectionActionButtonPosition
        === FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_FOOTER && customSelectionActionButton}
      </BasePage>
    );
  }
  return (
    <BasePage onConfirmPressed={onConfirmDeletePressed}>
      <div className={classes.headerField}>
        {TitleWithBackButton}

        {pageMode === PAGE_MODE_VIEW && useCreateButtonInViewMode ? renderCreateNewButton() : null}
      </div>
      <Divider className={classes.divider} />

      {pageMode === PAGE_MODE_VIEW && useTotalCountSection && (
      <TotalCountSection
        data={totalCountSectionData}
        mode={totalCountSectionMode}
        useCustomLastField={useTotalCountCustomLastField}
        customLastField={totalCountSectionCustomLastField}
      />
      )}

      {contentMode === FUNCTIONAL_PAGE_CONTENT_MODE_FIELD ? (
        <div className={classes.fieldContainer}>
          {useFullWidth ? children : (
            <Grid container>
              <Grid item sm={6}>
                {children}
              </Grid>
            </Grid>
          )}
          {renderAdditionalField()}
          {renderFloatingButton()}
        </div>
      ) : (children)}
    </BasePage>
  );
};

export default withRouter(withStyles(styles)(FunctionalPage));

FunctionalPage.propTypes = {
  customActions: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepContent: PropTypes.arrayOf(PropTypes.node),
  stepTitle: PropTypes.arrayOf(PropTypes.string),
  moreMenus: PropTypes.arrayOf(MoreMenuShape),
  filterColumns: PropTypes.arrayOf(TableColumnShape),
  filterSections: PropTypes.arrayOf(TableColumnShape),
  selectionActions: PropTypes.arrayOf(PropTypes.object),
  tableColumns: PropTypes.arrayOf(TableColumnShape),
  totalCountSectionData: PropTypes.arrayOf(TotalCountShape),
  addingEditing: PropTypes.bool,
  createPermission: PropTypes.bool.isRequired,
  cropping: PropTypes.bool.isRequired,
  customDeleteButtonMenuCaption: PropTypes.bool,
  deletePermission: PropTypes.bool.isRequired,
  disableCreate: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableView: PropTypes.bool,
  disableSaveSubmit: PropTypes.bool,
  disableActions: PropTypes.bool,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool,
  drawerVisibility: PropTypes.bool.isRequired,
  editPermission: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool,
  savePermission: PropTypes.bool.isRequired,
  saving: PropTypes.bool,
  selection: PropTypes.bool,
  sendingNotification: PropTypes.bool,
  sendNotificationPermission: PropTypes.bool,
  useBackButtonTableMode: PropTypes.bool,
  useCreateButtonInViewMode: PropTypes.bool,
  useFullWidth: PropTypes.bool,
  usefullWidthDialog: PropTypes.bool,
  useNotificationCount: PropTypes.bool,
  useSendAllNotification: PropTypes.bool,
  useTotalCountCustomLastField: PropTypes.bool,
  useTotalCountSection: PropTypes.bool,
  useTwoColumnFilterDialog: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onAdvancedFilterPressed: PropTypes.func,
  onAppear: PropTypes.func,
  onAppearWithDetailRoute: PropTypes.func,
  onApplyAdvancedFilterPressed: PropTypes.func,
  onBackPressed: PropTypes.func,
  onCancelAdvancedFilterPressed: PropTypes.func,
  onCancelPressed: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangePageSize: PropTypes.func,
  onConfirmDeletePressed: PropTypes.func,
  onCreatePressed: PropTypes.func,
  onCreateNewPressed: PropTypes.func,
  onDeletePressed: PropTypes.func,
  onDownloadPressed: PropTypes.func,
  onEditPressed: PropTypes.func,
  onFunctionalPageAdvancedFilterPressed: PropTypes.func.isRequired,
  onFunctionalPageAppear: PropTypes.func.isRequired,
  onFunctionalPageBackPressed: PropTypes.func.isRequired,
  onFunctionalPageEditPressed: PropTypes.func,
  onFunctionalPageViewPressed: PropTypes.func.isRequired,
  onFunctionalPageWithDetailRouteAppear: PropTypes.func.isRequired,
  onMorePressed: PropTypes.func,
  onSelectionActionPressed: PropTypes.func,
  onOpenSendNotificationDialog: PropTypes.func,
  onRefresh: PropTypes.func,
  onResetAdvancedFilterPressed: PropTypes.func,
  onSavePressed: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onSearchBarTextChanged: PropTypes.func,
  onSendNotificationPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func,
  onSubmitPressed: PropTypes.func,
  onViewPressed: PropTypes.func,
  renderCustomUpperButton: PropTypes.func,
  renderCustomFabComponent: PropTypes.func,
  onNavigateToRedirectFrom: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  customSelectionActionButton: PropTypes.node,
  totalCountSectionCustomLastField: PropTypes.node,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  menuPath: PropTypes.object.isRequired,
  contentMode: PropTypes.oneOf([FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM,
    FUNCTIONAL_PAGE_CONTENT_MODE_FIELD]),
  customSelectionActionButtonPosition: PropTypes.oneOf([
    FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_HEADER,
    FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_FOOTER]),
  pageMode: PropTypes.oneOf([PAGE_MODE_EDIT, PAGE_MODE_TABLE, PAGE_MODE_VIEW]).isRequired,
  totalCountSectionMode: PropTypes.oneOf(['flat', 'elevated']),
  rowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  addDialogHeaderContainerStyle: PropTypes.string,
  addDialogSubmitButtonCaption: PropTypes.string,
  createNewButtonCaption: PropTypes.string,
  createPermissionName: PropTypes.string,
  deleteButtonCaption: PropTypes.string,
  deletePermissionName: PropTypes.string,
  detailRoute: PropTypes.string,
  detailTitle: PropTypes.string,
  editButtonCaption: PropTypes.string,
  editPermissionName: PropTypes.string,
  filterString: PropTypes.string,
  savePermissionName: PropTypes.string,
  searchBarText: PropTypes.string.isRequired,
  sendNotificationPermissionName: PropTypes.string,
  orderBy: PropTypes.string.isRequired,
  tappedId: PropTypes.string,
  title: PropTypes.string.isRequired,
  backToUrl: PropTypes.string.isRequired,
};

FunctionalPage.defaultProps = {
  customActions: [],
  stepContent: [],
  stepTitle: [],
  moreMenus: [],
  filterColumns: [],
  filterSections: [],
  selectionActions: [],
  tableColumns: [],
  totalCountSectionData: [],
  addingEditing: false,
  customDeleteButtonMenuCaption: false,
  disableCreate: false,
  disableDelete: false,
  disableEdit: false,
  disableView: false,
  disableActions: false,
  disableSaveSubmit: false,
  downloadingDeleting: false,
  enablingDisabling: false,
  saving: false,
  selection: false,
  sendingNotification: false,
  sendNotificationPermission: false,
  useBackButtonTableMode: true,
  useCreateButtonInViewMode: false,
  useFullWidth: false,
  usefullWidthDialog: false,
  useNotificationCount: false,
  useSendAllNotification: false,
  useTotalCountCustomLastField: false,
  useTotalCountSection: false,
  useTwoColumnFilterDialog: false,
  handleSubmit: () => {},
  onAdvancedFilterPressed: () => {},
  onAppear: () => {},
  onAppearWithDetailRoute: () => {},
  onApplyAdvancedFilterPressed: () => {},
  onBackPressed: () => {},
  onCancelAdvancedFilterPressed: () => {},
  onCancelPressed: () => {},
  onChangePage: () => {},
  onChangePageSize: () => {},
  onConfirmDeletePressed: () => {},
  onCreatePressed: () => {},
  onCreateNewPressed: () => {},
  onDeletePressed: () => {},
  onDownloadPressed: () => {},
  onEditPressed: () => {},
  onFunctionalPageEditPressed: () => {},
  onMorePressed: () => {},
  onSelectionActionPressed: () => {},
  onOpenSendNotificationDialog: () => {},
  onRefresh: () => {},
  onResetAdvancedFilterPressed: () => {},
  onSavePressed: () => {},
  onSelectionChange: () => {},
  onSearchBarTextChanged: () => {},
  onSortPressed: () => {},
  onSubmitPressed: () => {},
  onViewPressed: () => {},
  renderCustomUpperButton: () => {},
  renderCustomFabComponent: () => {},
  customSelectionActionButton: null,
  totalCountSectionCustomLastField: (<div />),
  contentMode: FUNCTIONAL_PAGE_CONTENT_MODE_FIELD,
  customSelectionActionButtonPosition: FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_HEADER,
  totalCountSectionMode: 'flat',
  rowStyle: null,
  addDialogHeaderContainerStyle: '',
  addDialogSubmitButtonCaption: '',
  createNewButtonCaption: '',
  createPermissionName: '',
  deleteButtonCaption: '',
  deletePermissionName: '',
  detailRoute: '',
  detailTitle: '',
  editButtonCaption: '',
  editPermissionName: '',
  filterString: '',
  savePermissionName: '',
  sendNotificationPermissionName: '',
  tappedId: '',
};
