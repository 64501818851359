import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  FILE_EXTENSION_PDF, INITIAL_ORDER_BY_ASSET_DOCUMENTS, RXFIELD_ASSET_DOCUMENT_FILE,
  RXFORM_ASSET_DOCUMENT_TAB,
} from '../../../constant';
import { getData, getPermission } from '../../../helper';
import {
  addAssetDocumentAsync, clearAssetDocuments, deleteAssetDocumentAsync, downloadAssetDocumentsAsync,
  setAlertErrorMessage, setAssetDocumentAdvancedFilterDialogSelectedFilterString,
  setAssetDocumentSearchText, setAssetDocumentSelectedFile, setAssetDocumentSelectedOrderBy,
  setAssetDocumentSelectedPageSize,
  setAssetDocumentTappedId,
} from '../../../redux/action';
import DocumentTab from './document-tab.presentation';

const mapStateToProps = (state) => ({
  assetDocuments: getData(state.assetDocuments, state.uiAssetDocument),
  addingEditing: state.uiAssetDocument.addingEditing,
  downloading: state.uiAssetDocument.downloading,
  downloadingDeleting: state.uiAssetDocument.downloadingDeleting,
  hasCreatePermission: getPermission(state, 'ECERTIFICATION_CREATE_ASSET_DOCUMENT'),
  hasDeletePermission: getPermission(state, 'ECERTIFICATION_DELETE_ASSET_DOCUMENT'),
  currentPage: state.assetDocuments?.meta?.currentPage || 0,
  totalCount: state.assetDocuments?.meta?.totalCount || 0,
  selectedPageSize: state.uiAssetDocument?.selectedPageSize || 1,
  orderBy: state.uiAssetDocument?.orderBy || '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (tappedAssetId) => {
    dispatch(setAssetDocumentSelectedFile(null));
    dispatch(setAssetDocumentAdvancedFilterDialogSelectedFilterString(`asset.id=${tappedAssetId}`));
    dispatch(setAssetDocumentSearchText(''));
    dispatch(clearAssetDocuments());
    dispatch(setAssetDocumentSelectedPageSize(20));
    dispatch(setAssetDocumentSelectedOrderBy(INITIAL_ORDER_BY_ASSET_DOCUMENTS));
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetDocumentsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetDocumentSelectedPageSize(pageSize));
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (newData, oldData) => {
    dispatch(setAssetDocumentTappedId(oldData.id));
    dispatch(deleteAssetDocumentAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onFileSelected: (base64, fileName) => {
    dispatch(setAssetDocumentSelectedFile({ fileContent: base64, fileName, label: fileName }));
    dispatch(change(RXFORM_ASSET_DOCUMENT_TAB, RXFIELD_ASSET_DOCUMENT_FILE, base64));
  },
  onPdfPressed: async (fileUrl, fileName) => {
    try {
      const extension = fileUrl?.match(/(\.[^.]*)$/g)[0];
      const response = await fetch(fileUrl);
      if (extension === FILE_EXTENSION_PDF) {
        const arrbuff = await response.arrayBuffer();
        const blob = new Blob([arrbuff], {
          type: 'application/pdf',
        });
        const newWindow = window.open('', '_blank');
        const dataUrl = window.URL.createObjectURL(blob);

        const title = newWindow.document.createElement('title');
        const iframe = newWindow.document.createElement('iframe');

        newWindow.document.head.appendChild(title);

        iframe.setAttribute('src', dataUrl);
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('height', '100%');
        iframe.setAttribute('type', 'application/pdf');

        newWindow.document.body.appendChild(iframe);
      } else {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
      }
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
  onUploadPressed: () => {
    dispatch(addAssetDocumentAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setAssetDocumentSelectedOrderBy(orderBy));
    dispatch(clearAssetDocuments());
    dispatch(downloadAssetDocumentsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTab);
