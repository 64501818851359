import { connect } from 'react-redux';
import {
  INITIAL_ORDER_BY_CERTIFICATION_REQUESTS, INITIAL_ORDER_BY_PROFILES,
  MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST, ROUTE_NAME_CERTIFICATION_REQUEST,
  ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL,
} from '../../constant';
import { getPermission, transformDropdownData } from '../../helper';
import {
  approveCertificationRequestAsync, clearAssetLocations, clearCertificationRequests,
  clearCertificationTypes, clearProfiles, downloadAssetLocationsAsync,
  downloadCertificationRequestAsync, downloadCertificationRequestsAsync,
  downloadCertificationTypesAsync, rejectCertificationRequestAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setAssetLocationSearchText, setCertificationRequestActiveTab,
  setCertificationRequestAdvancedFilterDialogSelectedFilterString,
  setCertificationRequestSearchText, setCertificationRequestSelectedOrderBy,
  setCertificationRequestSelectedPageSize, setCertificationRequestTappedId,
  setCertificationTypeAdvancedFilterDialogSelectedFilterString, setCertificationTypeSearchText,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  setProfileSelectedOrderBy, setProfileSelectedPageSize,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import CertificationRequestPage from './certification-request.presentation';
import LocalizedString from '../../localization';

const mapStateToProps = (state) => ({
  certificationTypes: transformDropdownData(state.certificationTypes.data),
  owners: transformDropdownData(state.assetLocations.data),
  pics: transformDropdownData(state.profiles.data),
  downloading: state.uiCertificationRequest.downloading,
  hasApprovalPermission: getPermission(state, 'ECERTIFICATION_GET_CERTIFICATION_REQUEST'),
  hasAssetPermission: getPermission(state, 'ECERTIFICATION_GET_CERTIFICATION_REQUEST'),
  loadingCertificationTypes: state.uiCertificationType.downloading,
  loadingOwners: state.uiAsset.downloadingAssetLocations,
  loadingPics: state.uiProfile.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setAssetLocationSearchText(''));
    dispatch(clearAssetLocations());
    dispatch(downloadAssetLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedPageSize(20));
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: (id, defaultTab) => {
    dispatch(setActiveSideMenuItem(MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST));
    if (id) {
      dispatch(setCertificationRequestTappedId(id));
      dispatch(setCertificationRequestActiveTab(defaultTab));
      dispatch(downloadCertificationRequestAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setCertificationRequestSearchText(''));
      dispatch(clearCertificationRequests());
      dispatch(setCertificationRequestSelectedPageSize(20));
      dispatch(setCertificationRequestSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_REQUESTS));
      dispatch(downloadCertificationRequestsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/pic/, 'pic.id').replace(/assetLocation/, 'assetLocation.id').replace(/certificationType/, 'certificationType.id');
    dispatch(setCertificationRequestAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCertificationRequests());
    dispatch(downloadCertificationRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: (history) => {
    dispatch(setCertificationRequestTappedId(''));
    history.push(ROUTE_NAME_CERTIFICATION_REQUEST);
  },
  onChangeCertTypeText: async (text) => {
    try {
      dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCertificationTypeSearchText(text));
      dispatch(clearCertificationTypes());
      await dispatch(downloadCertificationTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeOwnerText: async (text) => {
    try {
      dispatch(setAssetLocationSearchText(text));
      dispatch(clearAssetLocations());
      await dispatch(downloadAssetLocationsAsync());
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePicText: async (text) => {
    try {
      dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCertificationRequestsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCertificationRequestSelectedPageSize(pageSize));
    dispatch(downloadCertificationRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.certificationRequestPage.msgApproval1Confirmation:
        dispatch(approveCertificationRequestAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.certificationRequestPage.msgRejection1Confirmation:
        dispatch(rejectCertificationRequestAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default: break;
    }
  },
  onCreatePressed: () => {
    dispatch(setCertificationRequestTappedId(''));
  },
  onRefresh: (pageSize) => {
    dispatch(setCertificationRequestSelectedPageSize(pageSize));
    dispatch(clearCertificationRequests());
    dispatch(downloadCertificationRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCertificationRequestAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCertificationRequests());
    dispatch(setCertificationRequestSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_REQUESTS));
    dispatch(downloadCertificationRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCertificationRequestSearchText(text));
      dispatch(clearCertificationRequests());
      await dispatch(downloadCertificationRequestsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCertificationRequestSelectedOrderBy(orderBy));
    dispatch(clearCertificationRequests());
    dispatch(downloadCertificationRequestsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id, history, defaultTab) => {
    dispatch(setCertificationRequestTappedId(id));
    dispatch(setCertificationRequestActiveTab(defaultTab));
    history.push(ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL.replace(':id', id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationRequestPage);
