/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Divider, IconButton, makeStyles } from '@material-ui/core';
import {
  COLOR_SECONDARY, DEFAULT_MAX_FILE_SIZE, ROUTE_NAME_ASSET_DETAIL, RXFIELD_ASSET_DOCUMENT_FILE,
  RXFORM_ASSET_DOCUMENT_TAB,
} from '../../../constant';
import { AccentButton, EditableTableField } from '../../../component';
import LocalizedString from '../../../localization';
import GlobalLocalizedString from '../../../../../localization';
import { renderReduxFormDropzonePicker } from '../../../../../redux-form-rendererer';
import { rxformValidateAssetDocumentTab } from '../../../validation';

export const FILE_EXTENSIONS = {
  'application/pdf': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
};

const useStyles = makeStyles(() => ({
  filePickerContainer: {
    marginLeft: '-8px',
  },
  divider: {
    backgroundColor: COLOR_SECONDARY,
    margin: '8px 0px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const pdfIcon = require('../../../../../asset/pdf-icon.png');

const DocumentTab = ({
  assetDocuments,
  addingEditing, downloading, downloadingDeleting, hasCreatePermission, hasDeletePermission,
  handleSubmit, onAppear, onChangePage, onChangePageSize, onDeletePressed, onFileSelected,
  onPdfPressed, onSortPressed, onUploadPressed,
  currentPage, totalCount, selectedPageSize, orderBy,
}) => {
  const classes = useStyles();
  const isDetailRoute = useRouteMatch(ROUTE_NAME_ASSET_DETAIL);
  const detailIdParam = isDetailRoute?.params?.id;

  useEffect(() => onAppear(detailIdParam), [onAppear, detailIdParam]);

  return (
    <div>
      {hasCreatePermission && (
      <Field
        name={RXFIELD_ASSET_DOCUMENT_FILE}
        component={renderReduxFormDropzonePicker}
        disabled={addingEditing || downloading}
        helperText={LocalizedString.assetPage.msgFileValidation}
        onBlur={(e) => e.preventDefault()}
        acceptedFileExtension={FILE_EXTENSIONS}
        onFileSelected={onFileSelected}
        containerStyle={classes.filePickerContainer}
        maxSize={DEFAULT_MAX_FILE_SIZE}
      />
      )}

      {hasCreatePermission && (
      <div className={classes.rowContainer}>
        <div style={{ flex: 1 }} />
        <AccentButton
          onClick={handleSubmit(onUploadPressed)}
          variant="contained"
          caption={GlobalLocalizedString.common.buttonCaptionUpload}
          disabled={downloading}
          loading={addingEditing}
        />
      </div>
      )}

      {hasCreatePermission && (<Divider className={classes.divider} />)}

      <EditableTableField
        data={assetDocuments}
        loading={downloading || downloadingDeleting}
        tableColumns={[
          {
            title: LocalizedString.assetPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          {
            title: LocalizedString.assetPage.labelDocument,
            field: 'file',
            sorting: !downloading,
            render: ({ file, fileName }) => (file ? (
              <IconButton aria-label="download file" onClick={() => onPdfPressed(file, fileName)}>
                <img alt="cert-file" src={pdfIcon} width={20} height={20} />
              </IconButton>
            ) : null),
          },
          { title: LocalizedString.assetPage.labelFileName, field: 'fileName', sorting: !downloading },
        ]}
        disableDelete={!hasDeletePermission}
        disableEdit
        disableToolbar
        paging
        search
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onDeletePressed={onDeletePressed}
        onSortPressed={onSortPressed}
        currentPage={currentPage}
        totalCount={totalCount}
        selectedPageSize={selectedPageSize}
        orderBy={orderBy}
      />
    </div>
  );
};

export default reduxForm({
  form: RXFORM_ASSET_DOCUMENT_TAB,
  validate: rxformValidateAssetDocumentTab,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DocumentTab);

DocumentTab.propTypes = {
  assetDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  hasCreatePermission: PropTypes.bool.isRequired,
  hasDeletePermission: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onPdfPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onUploadPressed: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
};
