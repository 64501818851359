import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CERT_SCHEDULE_SETTING_DIALOG_MODE_ADD, CERT_SCHEDULE_SETTING_DIALOG_MODE_SET,
  ERR_TOKEN_SESSION_EXPIRED, INITIAL_ORDER_BY_ASSETS, INITIAL_ORDER_BY_CERTIFICATION_TYPES,
  MENUID_E_CERTIFICATION_CERTIFICATION_SCHEDULE_SETTING, PAGE_MODE_TABLE,
  ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING, ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL,
  ROUTE_NAME_LOGIN,
} from '../../constant';
import {
  debounceSearch, getPermission, removeAllStorage, transformDropdownData, transformInitialValues,
  transformUserDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearAllStates,
  clearAssetLocations, clearAssets, clearAssetTypes, clearCertificationTypes, downloadAssetAsync,
  downloadAssetLocationsAsync, downloadAssetsAsync, downloadAssetTypesAsync,
  downloadCertificationTypesAsync, downloadDeleteAssetCertificateAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setAssetAdvancedFilterDialogSelectedFilterString,
  setAssetCertificateTappedId, setAssetLocationSearchText, setAssetSearchText,
  setAssetSelectedOrderBy, setAssetSelectedPageSize, setAssetTappedId, setAssetTypeSearchText,
  setCertificationScheduleSettingDialogVisibility,
  setCertificationTypeAdvancedFilterDialogSelectedFilterString, setCertificationTypeSearchText,
  setCertificationTypeSelectedOrderBy, setCertificationTypeSelectedPageSize,
} from '../../redux/action';
import { downloadManagersAsync, downloadProfilesAsync, logoutAsync } from '../../../../redux/action';
import {
  clearManagers, clearProfiles, downloadMyConfigItemsAsync,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileManagerSearchText,
  setProfileSearchText,
} from '../../../configuration/redux/action';
import CertificationScheduleSettingPage from './certification-schedule-setting.presentation';

const DEFAULT_FILTER_STRING = 'certificationRequestStatus=Approved';

const getInitialValues = (state) => {
  const {
    assets, uiFunctionalPage, uiAsset,
  } = state;
  const { data } = assets;
  const { downloadingDeleting, tappedId } = uiAsset;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  assetTypes: transformDropdownData(state.assetTypes.data),
  certificationTypes: transformDropdownData(state.certificationTypes.data),
  locations: transformDropdownData(state.assetLocations.data),
  managers: transformUserDropdownData(state.managers.data),
  profiles: transformUserDropdownData(state.profiles.data),
  hasSetScheduleCertificationsPermission: getPermission(state, 'ECERTIFICATION_CREATE_ASSET_SCHEDULING'),
  downloading: state.uiAsset.downloading,
  loadingAssetTypes: state.uiAsset.downloadingAssetTypes,
  loadingCertificationTypes: state.uiCertificationType.downloading,
  loadingLocations: state.uiAsset.downloadingAssetLocations,
  loadingManagers: state.uiProfile.downloadingManagers,
  loadingProfiles: state.uiProfile.downloading,
  myConfigItems: state.myConfigItems,
  pageMode: state.uiFunctionalPage.pageMode,
});

const searchManagerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setAssetTypeSearchText(''));
    dispatch(clearAssetTypes());
    dispatch(downloadAssetTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setAssetLocationSearchText(''));
    dispatch(clearAssetLocations());
    dispatch(downloadAssetLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCertificationTypeSearchText(''));
    dispatch(clearCertificationTypes());
    dispatch(setCertificationTypeSelectedPageSize(20));
    dispatch(setCertificationTypeSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_TYPES));
    dispatch(downloadCertificationTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setProfileManagerSearchText(''));
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: (id) => {
    dispatch(setActiveSideMenuItem(MENUID_E_CERTIFICATION_CERTIFICATION_SCHEDULE_SETTING));
    if (id) {
      dispatch(setAssetTappedId(id));
      dispatch(downloadAssetAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setAssetAdvancedFilterDialogSelectedFilterString(DEFAULT_FILTER_STRING));
      dispatch(setAssetSearchText(''));
      dispatch(clearAssets());
      dispatch(setAssetSelectedPageSize(20));
      dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
      dispatch(downloadAssetsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/location/, 'location.id').replace(/category/, 'category.id').replace(/certificateSubmitter/, 'certificateSubmitter.id').replace(/owner/, 'owner.id')
      .replace(/certificationType/, 'certificationType.id');
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(`${DEFAULT_FILTER_STRING}${text}`));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: (history) => {
    dispatch(setAssetTappedId(''));
    history.push(ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING);
  },
  onChangeAssetTypeText: async (text) => {
    try {
      dispatch(setAssetTypeSearchText(text));
      dispatch(clearAssetTypes());
      await dispatch(downloadAssetTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCertTypeText: async (text) => {
    try {
      dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCertificationTypeSearchText(text));
      dispatch(clearCertificationTypes());
      await dispatch(downloadCertificationTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeManagerText: (text) => {
    dispatch(setProfileManagerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchManagerDebounce(dispatch);
    }
  },
  onChangeLocationText: async (text) => {
    try {
      dispatch(setAssetLocationSearchText(text));
      dispatch(clearAssetLocations());
      await dispatch(downloadAssetLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetSelectedPageSize(pageSize));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProfileText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onConfirmDeletePressed: async (message, reason, history, myConfigItems) => {
    if (message.toLowerCase().includes(ERR_TOKEN_SESSION_EXPIRED)) {
      await removeAllStorage();
      dispatch(clearAllStates());
      history.push(ROUTE_NAME_LOGIN);
    } else if (message === GlobalLocalizedString.common.msgLogoutConfirmation) {
      if (isEmpty(myConfigItems)) {
        await dispatch(downloadMyConfigItemsAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
      dispatch(logoutAsync(history.push, message))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      dispatch(downloadDeleteAssetCertificateAsync())
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    }
  },
  onCreatePressed: () => {
    dispatch(setAssetCertificateTappedId(''));
    dispatch(setCertificationScheduleSettingDialogVisibility(true,
      CERT_SCHEDULE_SETTING_DIALOG_MODE_ADD));
  },
  onRefresh: (pageSize) => {
    dispatch(setAssetSelectedPageSize(pageSize));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(DEFAULT_FILTER_STRING));
    dispatch(clearAssets());
    dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAssetSearchText(text));
      dispatch(clearAssets());
      await dispatch(downloadAssetsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSetSchedulePressed: () => {
    dispatch(setCertificationScheduleSettingDialogVisibility(true,
      CERT_SCHEDULE_SETTING_DIALOG_MODE_SET));
  },
  onSortPressed: (orderBy) => {
    dispatch(setAssetSelectedOrderBy(orderBy));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id, history) => {
    dispatch(setAssetTappedId(id));
    history.push(ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL.replace(':id', id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationScheduleSettingPage);
