/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Paper } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { EditableTableField, InformationCard } from '../../../component';
import {
  COLOR_SECONDARY, REVERSED_ISO_DATE_FORMAT, ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL,
} from '../../../constant';
import GlobalLocalizedString from '../../../../../localization';
import LocalizedString from '../../../localization';
import { toMoment } from '../../../helper';
import { FormInitialValueShape } from '../../../type';
import AddEditCertificationScheduleSettingDialog from '../add-edit-dialog';

const pdfIcon = require('../../../../../asset/pdf-icon.png');

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderBottom: `1px solid ${COLOR_SECONDARY}`,
    borderLeft: `1px solid ${COLOR_SECONDARY}`,
    borderRight: `1px solid ${COLOR_SECONDARY}`,
    padding: 8,
  },
}));

const CertificationScheduleSettingPage = ({
  initialValues, assetCertificates,
  downloadingAssetCertificates, downloadingDeleting,
  hasDeleteCertificationsPermission, hasEditCertificationsPermission,
  hasGetCertificationsPermission,
  onAppear, onChangePage, onChangePageSize, onDeletePressed, onEditPressed, onPdfPressed,
  onSortPressed,
  currentPage, totalCount, selectedPageSize, orderBy,
}) => {
  const [tappedDocumentId, setTappedDocumentId] = useState();

  const classes = useStyles();

  const informationCardData = {
    title: LocalizedString.certificationScheduleSettingPage.detailTitle,
    body: [
      {
        row: [
          {
            label: LocalizedString.certificationScheduleSettingPage.labelDescription,
            value: initialValues?.assetDescription,
          },
          {
            label: LocalizedString.certificationScheduleSettingPage.labelAssetNumber,
            value: initialValues?.assetNumber,
          },
        ],
      },
      {
        row: [
          {
            label: LocalizedString.certificationScheduleSettingPage.labelOwner,
            value: initialValues?.owner?.fullName,
          },
          {
            label: LocalizedString.certificationScheduleSettingPage.labelType,
            value: initialValues?.certificationType?.name,
          },
        ],
      },
      {
        row: [
          {
            label: LocalizedString.certificationScheduleSettingPage.labelPartCode,
            value: initialValues?.partCode,
          },
          {
            label: LocalizedString.certificationScheduleSettingPage.labelSerialNumber,
            value: initialValues?.serialNumber,
          },
        ],
      },
      {
        row: [
          {
            label: LocalizedString.certificationScheduleSettingPage.labelSchedule,
            value: initialValues?.certificationScheduledDate ? toMoment(initialValues?.certificationScheduledDate).format(REVERSED_ISO_DATE_FORMAT) : '',
          },
          {
            label: LocalizedString.certificationScheduleSettingPage.labelCategory,
            value: initialValues?.category?.name,
          },
        ],
      },
      {
        row: [
          {
            label: LocalizedString.certificationScheduleSettingPage.labelLocation,
            value: initialValues?.location?.name,
          },
        ],
      },
    ],
  };

  const isDetailRoute = useRouteMatch(ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL);
  const detailIdParam = isDetailRoute?.params?.id;

  useEffect(() => onAppear(detailIdParam), [onAppear, detailIdParam]);

  return (
    <div className={classes.container}>
      <InformationCard data={informationCardData} loading={downloadingDeleting} />

      {hasGetCertificationsPermission && (
      <Paper className={classes.contentContainer}>
        <EditableTableField
          data={assetCertificates}
          loading={downloadingAssetCertificates}
          tableColumns={[
            {
              title: LocalizedString.certificationScheduleSettingPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
            },
            {
              title: LocalizedString.certificationScheduleSettingPage.labelResultType,
              field: 'resultType',
              sorting: !downloadingAssetCertificates,
              render: ({ asset: { assetNumber }, file, issuedDate }) => (file ? (
                <IconButton
                  aria-label="download file"
                  onClick={() => onPdfPressed(assetNumber, file, issuedDate
                    ? toMoment(issuedDate).format(REVERSED_ISO_DATE_FORMAT) : null)}
                >
                  <img alt="cert-file" src={pdfIcon} width={20} height={20} />
                </IconButton>
              ) : null),
            },
            {
              title: LocalizedString.certificationScheduleSettingPage.labelIssueDate,
              field: 'issuedDate',
              sorting: !downloadingAssetCertificates,
              render: ({ issuedDate }) => (issuedDate
                ? toMoment(issuedDate).format(REVERSED_ISO_DATE_FORMAT) : null),
            },
            {
              title: LocalizedString.certificationScheduleSettingPage.labelExpiredDate,
              field: 'expiredDate',
              sorting: !downloadingAssetCertificates,
              render: ({ expiredDate }) => (expiredDate
                ? toMoment(expiredDate).format(REVERSED_ISO_DATE_FORMAT) : null),
            },
            { title: LocalizedString.certificationScheduleSettingPage.labelRemark, field: 'remark', sorting: !downloadingAssetCertificates },
          ]}
          moreMenuList={[
            {
              caption: GlobalLocalizedString.common.buttonCaptionEdit,
              disabled: !hasEditCertificationsPermission,
              onPress: () => onEditPressed(tappedDocumentId),
            },
            {
              caption: GlobalLocalizedString.common.buttonCaptionDelete,
              disabled: !hasDeleteCertificationsPermission,
              onPress: () => onDeletePressed(tappedDocumentId),
            },
          ]}
          actionsColumnIndex={5}
          disableActions={false}
          disabled
          disableToolbar
          paging
          onChangePage={onChangePage}
          onMorePressed={(id) => setTappedDocumentId(id)}
          onChangePageSize={onChangePageSize}
          onSortPressed={onSortPressed}
          currentPage={currentPage}
          totalCount={totalCount}
          selectedPageSize={selectedPageSize}
          orderBy={orderBy}
        />
      </Paper>
      )}

      <AddEditCertificationScheduleSettingDialog />
    </div>
  );
};

export default CertificationScheduleSettingPage;

CertificationScheduleSettingPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  assetCertificates: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloadingAssetCertificates: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  hasDeleteCertificationsPermission: PropTypes.bool.isRequired,
  hasEditCertificationsPermission: PropTypes.bool.isRequired,
  hasGetCertificationsPermission: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onPdfPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
};
