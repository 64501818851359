import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    approvalMenu: {
      title: 'Approval',
    },
    approvalPage: {
      title: 'Approval',

      labelNo: 'No',
      labelProcessName: 'Process Name',
      labelApprovalNumber: 'Approval Number',
      labelTitle: 'Title',
      labelRequester: 'Requester',
      labelRequesterId: 'Requester ID',
      labelRequesterName: 'Requester Name',
      labelRequesterNotes: 'Requester Notes',
      labelApprovalStatus: 'Approval Status',
      labelCreatedDate: 'Created Date',
      labelStatusChangedAt: 'Status Changed At',
      labelProviderName: 'Provider Name',
      labelDescription: 'Description',
      labelNotes: 'Notes',
      labelReferenceId: 'Reference ID',
      labelAllowToAddAttachment: 'Allow To Add Attachment',
      labelDisableAppNotification: 'Disable App Notification',
      labelDisableEmailNotification: 'Disable Email Notification',
      labelSteps: 'Steps',
      labelOrder: 'Order',
      labelLabel: 'Label',
      labelStatus: 'Status',
      labelModifiedAt: 'Modified At',
      labelAttachments: 'Attachments',
      labelFileName: 'File Name',
      labelData: 'Data',
      labelFieldName: 'Field Name',
      labelValue: 'Value',
      labelUserId: 'User ID',
      labelFullName: 'Full Name',
      labelStartedAt: 'Started At',
      labelTag: 'Tags',

      placeholderResendEvent: 'Resend Event',

      buttonCaptionApprovers: 'Approvers',
      buttonCaptionComments: 'Comments',
      buttonCaptionResendEvent: 'Resend Event',

      msgConfirmResendEvent: 'Are you sure to resend this event?',
    },
    approvalGroupPage: {
      title: 'Approval Group',

      labelNo: 'No',
      labelName: 'Name',
      labelGroupName: 'Group Name',
      labelDescription: 'Description',
      labelCreatedDate: 'Created Date',
      labelStatus: 'Status',
      labelGroupMembers: 'Group Members',
      labelID: 'ID',
      labelEmail: 'Email',

      buttonCaptionCreateNewApprovalGroup: 'Create New Approval Group',
      buttonCaptionEditApprovalGroup: 'Edit Approval Group',
      buttonCaptionDeleteApprovalGroup: 'Delete Approval Group',
    },
  },
  id: {
    approvalMenu: {
      title: 'Persetujuan',
    },
    approvalPage: {
      title: 'Persetujuan',

      labelNo: 'No',
      labelProcessName: 'Nama Proses',
      labelApprovalNumber: 'Nomor Persetujuan',
      labelTitle: 'Judul',
      labelRequester: 'Pengaju',
      labelRequesterId: 'ID Pengaju',
      labelRequesterName: 'Nama Pengaju',
      labelRequesterNotes: 'Catatan Pengaju',
      labelApprovalStatus: 'Status Persetujuan',
      labelCreatedDate: 'Tanggal Dibuat',
      labelStatusChangedAt: 'Status Berubah Pada',
      labelProviderName: 'Nama Penyedia',
      labelDescription: 'Deskripsi',
      labelNotes: 'Catatan',
      labelReferenceId: 'ID Referensi',
      labelAllowToAddAttachment: 'Izinkan untuk Menambahkan Lampiran',
      labelDisableAppNotification: 'Matikan Notifikasi Aplikasi',
      labelDisableEmailNotification: 'Matikan Notifikasi Email',
      labelSteps: 'Langkah',
      labelOrder: 'Urutan',
      labelLabel: 'Label',
      labelStatus: 'Status',
      labelModifiedAt: 'Diubah Pada',
      labelAttachments: 'Lampiran',
      labelFileName: 'Nama File',
      labelData: 'Data',
      labelFieldName: 'Nama Field',
      labelValue: 'Nilai',
      labelUserId: 'ID Pengguna',
      labelFullName: 'Nama Lengkap',
      labelStartedAt: 'Dimulai pada',
      labelTag: 'Penanda',

      placeholderResendEvent: 'Kirim Ulang Event',

      buttonCaptionApprovers: 'Penyetuju',
      buttonCaptionComments: 'Komentar',
      buttonCaptionResendEvent: 'Kirim Ulang Event',

      msgConfirmResendEvent: 'Apakah Anda yakin untuk mengirim ulang event ini?',
    },
    approvalGroupPage: {
      title: 'Grup Persetujuan',

      labelNo: 'No',
      labelName: 'Nama',
      labelGroupName: 'Nama Grup',
      labelDescription: 'Deskripsi',
      labelCreatedDate: 'Tanggal Dibuat',
      labelStatus: 'Status',
      labelGroupMembers: 'Anggota Grup',
      labelID: 'ID',
      labelEmail: 'Email',

      buttonCaptionCreateNewApprovalGroup: 'Buat Grup Persetujuan Baru',
      buttonCaptionEditApprovalGroup: 'Ubah Grup Persetujuan',
      buttonCaptionDeleteApprovalGroup: 'Hapus Grup Persetujuan',
    },
  },
});

export default LocalizedString;
