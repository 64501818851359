import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  MENUID_E_CERTIFICATION_MY_APPROVAL,
  INITIAL_ORDER_BY_CERTIFICATION_MY_APPROVALS,
  RXFORM_CERTIFICATION_MY_APPROVAL,
  ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL,
} from '../../constant';
import {
  setActiveSideMenuItem, setAlertErrorMessage,
  setCertificationMyApprovalSearchText, clearCertificationMyApprovals,
  setCertificationMyApprovalSelectedPageSize,
  setCertificationMyApprovalSelectedOrderBy,
  setCertificationMyApprovalAdvancedFilterDialogSelectedFilterString,
  setCertificationMyApprovalTappedId,
  downloadCertificationMyApprovalsAsync,
  setCertificationRequestTappedId,
  setCertificationRequestActiveTab,
  downloadCertificationRequestAsync,
} from '../../redux/action';
import CertificationMyApprovalPage from './my-approval.presentation';

const mapStateToProps = (state) => ({
  downloading: state.uiCertificationMyApproval.downloading,
  downloadingDeleting: state.uiCertificationMyApproval.downloadingDeleting,
  myApprovals: Object.values(state.certificationMyApprovals.data),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (id, defaultTab) => {
    dispatch(setActiveSideMenuItem(MENUID_E_CERTIFICATION_MY_APPROVAL));
    dispatch(setCertificationMyApprovalAdvancedFilterDialogSelectedFilterString(''));
    if (id) {
      dispatch(setCertificationRequestTappedId(id));
      dispatch(setCertificationRequestActiveTab(defaultTab));
      dispatch(setCertificationMyApprovalTappedId(id));
      dispatch(downloadCertificationRequestAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setCertificationMyApprovalSearchText(''));
      dispatch(clearCertificationMyApprovals());
      dispatch(setCertificationMyApprovalSelectedPageSize(20));
      dispatch(setCertificationMyApprovalSelectedOrderBy(
        INITIAL_ORDER_BY_CERTIFICATION_MY_APPROVALS,
      ));
      dispatch(downloadCertificationMyApprovalsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setCertificationMyApprovalAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearCertificationMyApprovals());
    dispatch(downloadCertificationMyApprovalsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCertificationMyApprovalsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCertificationMyApprovalSelectedPageSize(pageSize));
    dispatch(downloadCertificationMyApprovalsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setCertificationMyApprovalSelectedPageSize(pageSize));
    dispatch(clearCertificationMyApprovals());
    dispatch(downloadCertificationMyApprovalsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCertificationMyApprovals());
    dispatch(setCertificationMyApprovalAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCertificationMyApprovalSelectedOrderBy(
      INITIAL_ORDER_BY_CERTIFICATION_MY_APPROVALS,
    ));
    dispatch(downloadCertificationMyApprovalsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCertificationMyApprovalSearchText(text));
      dispatch(clearCertificationMyApprovals());
      await dispatch(downloadCertificationMyApprovalsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCertificationMyApprovalSelectedOrderBy(orderBy));
    dispatch(clearCertificationMyApprovals());
    dispatch(downloadCertificationMyApprovalsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id, history, defaultTab, myApprovals) => {
    const found = myApprovals.find((x) => x.id === id);
    const referenceId = found?.referenceId || '';

    dispatch(setCertificationMyApprovalTappedId(id));
    dispatch(setCertificationRequestTappedId(referenceId));
    dispatch(setCertificationRequestActiveTab(defaultTab));
    history.push(ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL.replace(':id', referenceId));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CERTIFICATION_MY_APPROVAL));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationMyApprovalPage);
