import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  ASSET_NEED_CERTIFICATION_FILTER_STRING, INITIAL_ORDER_BY_ASSETS,
  INITIAL_ORDER_BY_CERTIFICATION_TYPES, MENUID_E_CERTIFICATION_ASSET, PAGE_MODE_TABLE,
  ROUTE_NAME_ASSET, ROUTE_NAME_ASSET_DETAIL, ROUTE_NAME_CERTIFICATION_REQUEST,
} from '../../constant';
import { getPermission, transformDropdownData, transformInitialValues } from '../../helper';
import {
  clearAssetLocations, clearAssets, clearAssetTypes, clearCertificationTypes,
  downloadAssetLocationsAsync, downloadAssetsAsync, downloadAssetTypesAsync,
  downloadCertificationTypesAsync, downloadAssetAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setAssetActiveTab, setAssetAdvancedFilterDialogSelectedFilterString,
  setAssetLocationSearchText, setAssetSearchText, setAssetSelectedOrderBy, setAssetSelectedPageSize,
  setAssetTappedId, setAssetTypeSearchText,
  setCertificationTypeAdvancedFilterDialogSelectedFilterString, setCertificationTypeSearchText,
  setCertificationTypeSelectedOrderBy, setCertificationTypeSelectedPageSize,
  setCertificationRequestTappedId,
} from '../../redux/action';
import AssetPage from './asset.presentation';

const getInitialValues = (state) => {
  const {
    assets, uiFunctionalPage, uiAsset,
  } = state;
  const { data } = assets;
  const { downloadingDeleting, tappedId } = uiAsset;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  assetTypes: transformDropdownData(state.assetTypes.data),
  certificationTypes: transformDropdownData(state.certificationTypes.data),
  locations: transformDropdownData(state.assetLocations.data),
  downloading: state.uiAsset.downloading,
  downloadingDeleting: state.uiAsset.downloadingDeleting,
  hasAssetChildPermission: getPermission(state, 'ECERTIFICATION_GET_ASSET'),
  hasCertificationsPermission: getPermission(state, 'ECERTIFICATION_GET_ASSET_CERTIFICATE'),
  hasDocumentPermission: getPermission(state, 'ECERTIFICATION_GET_ASSET_DOCUMENT'),
  hasSettingPermission: getPermission(state, 'ECERTIFICATION_SET_ASSET_REMINDER'),
  loadingAssetTypes: state.uiAsset.downloadingAssetTypes,
  loadingCertificationTypes: state.uiCertificationType.downloading,
  loadingLocations: state.uiAsset.downloadingAssetLocations,
  activeTab: state.uiAsset.activeTab,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setAssetTypeSearchText(''));
    dispatch(clearAssetTypes());
    dispatch(downloadAssetTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setAssetLocationSearchText(''));
    dispatch(clearAssetLocations());
    dispatch(downloadAssetLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCertificationTypeSearchText(''));
    dispatch(clearCertificationTypes());
    dispatch(setCertificationTypeSelectedPageSize(20));
    dispatch(setCertificationTypeSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_TYPES));
    dispatch(downloadCertificationTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: (id, defaultTab) => {
    dispatch(setActiveSideMenuItem(MENUID_E_CERTIFICATION_ASSET));
    if (id) {
      dispatch(setAssetTappedId(id));
      dispatch(setAssetActiveTab(defaultTab));
      dispatch(downloadAssetAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setAssetAdvancedFilterDialogSelectedFilterString(
        ASSET_NEED_CERTIFICATION_FILTER_STRING,
      ));
      dispatch(setAssetSearchText(''));
      dispatch(clearAssets());
      dispatch(setAssetSelectedPageSize(20));
      dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
      dispatch(downloadAssetsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/location/, 'location.id').replace(/category/, 'category.id').replace(/certificationType/, 'certificationType.id');
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(`${ASSET_NEED_CERTIFICATION_FILTER_STRING}${text}`));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: (history) => {
    dispatch(setAssetTappedId(''));
    history.push(ROUTE_NAME_ASSET);
  },
  onChangeAssetTypeText: async (text) => {
    try {
      dispatch(setAssetTypeSearchText(text));
      dispatch(clearAssetTypes());
      await dispatch(downloadAssetTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeCertTypeText: async (text) => {
    try {
      dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCertificationTypeSearchText(text));
      dispatch(clearCertificationTypes());
      await dispatch(downloadCertificationTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeLocationText: async (text) => {
    try {
      dispatch(setAssetLocationSearchText(text));
      dispatch(clearAssetLocations());
      await dispatch(downloadAssetLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetSelectedPageSize(pageSize));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: (history) => {
    dispatch(setAssetTappedId(''));
    dispatch(setCertificationRequestTappedId(''));
    history.push(ROUTE_NAME_CERTIFICATION_REQUEST);
  },
  onRefresh: (pageSize) => {
    dispatch(setAssetSelectedPageSize(pageSize));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(
      ASSET_NEED_CERTIFICATION_FILTER_STRING,
    ));
    dispatch(clearAssets());
    dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAssetSearchText(text));
      dispatch(clearAssets());
      await dispatch(downloadAssetsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAssetSelectedOrderBy(orderBy));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onTabPressed: (tab) => {
    dispatch(setAssetActiveTab(tab));
  },
  onViewPressed: (id, history, defaultTab) => {
    dispatch(setAssetTappedId(id));
    dispatch(setAssetActiveTab(defaultTab));
    history.push(ROUTE_NAME_ASSET_DETAIL.replace(':id', id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetPage);
